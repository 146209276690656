import React from 'react';
import Slider from 'react-slick';
import tw from 'twin.macro';
import styled, { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from '../Misc/Layouts';
import { SectionHeading, Subheading as SubheadingBase } from '../Misc/Headings';
import { SectionDescription } from '../Misc/Typography';

import 'slick-carousel/slick/slick.css';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import svgDecoratorBlob1 from '../../images/svg-decorator-blob-9.svg';

const PrimaryBackgroundContainer = tw(Container)`-mx-8 px-8 bg-primary-900 text-gray-100`;

const HeadingContainer = tw.div``;
const Subheading = tw(SubheadingBase)`text-center text-gray-100 mb-4`;
const Heading = tw(SectionHeading)``;
const Description = tw(SectionDescription)`mx-auto text-center text-gray-300`;

const TestimonialsSlider = styled(Slider)`
  ${tw`flex mt-16 mx-auto max-w-xs sm:max-w-xl lg:max-w-4xl text-left bg-gray-100 rounded-lg text-gray-900`}
  .slick-track {
    ${tw`flex!`}
  }
  .slick-slide {
    ${tw`h-auto`}
  }
  .slick-slide > div {
    ${tw`h-full`}
  }
`;
const Testimonial = tw.div`px-6 py-12 sm:px-20 sm:py-16 focus:outline-none flex! flex-col justify-between h-full`;
const QuoteContainer = tw.div`relative`;
const Quote = tw.blockquote`font-medium sm:font-normal relative text-sm sm:text-xl text-left`;
const CustomerName = tw.span`text-lg font-semibold text-left`;

const DecoratorBlob1 = () => (
    <img src={svgDecoratorBlob1} tw="absolute top-1/2 left-0 w-80 h-80 transform -translate-x-56 -translate-y-32 text-primary-500 opacity-5" alt="" />
);

const DecoratorBlob2 = () => (
    <img src={svgDecoratorBlob1} tw="absolute bottom-0 right-0 w-80 h-80 transform  -translate-x-20 translate-y-64 text-primary-500 opacity-5" alt="" />
);

export default ({
    subheading = false,
    description = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
}) => {
    const { t } = useTranslation();

    const testimonials = [
        {
            name: t('testimonials.cards.1.name'),
            quote: t('testimonials.cards.1.quote'),
            url: 'https://undraw.co/',
        },
    ];

    return (
        <PrimaryBackgroundContainer>
            <ContentWithPaddingXl>
                <HeadingContainer>
                    {subheading && <Subheading>{t('testimonials.subheading')}</Subheading>}
                    <Heading>{t('testimonials.heading')}</Heading>
                    {/* <Description>{description}</Description> */}
                </HeadingContainer>
                {testimonials.map((testimonial, index) => (
                    <TestimonialsSlider arrows={false}>
                        <Testimonial key={index}>
                            <QuoteContainer>
                                <a href={testimonial.url} target="_blank">
                                    <CustomerName>
                                        {testimonial.name}
                                    </CustomerName>
                                </a>
                                <Quote>
                                    {testimonial.quote}
                                </Quote>
                            </QuoteContainer>
                        </Testimonial>
                    </TestimonialsSlider>
                ))}
            </ContentWithPaddingXl>
            <DecoratorBlob1 />
            <DecoratorBlob2 />
        </PrimaryBackgroundContainer>
    );
};
