import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { css } from 'styled-components/macro';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Container, ContentWithPaddingXl } from '../Misc/Layouts';
import { SectionHeading, Subheading as SubheadingBase } from '../Misc/Headings';
import { SectionDescription } from '../Misc/Typography';
import twitterIcon from '../../images/twitter-icon.svg';
import linkedinIcon from '../../images/linkedin-icon.svg';
import githubIcon from '../../images/github-icon.svg';
import image from '../../images/undraw_profile_pic_ic5t.svg';

const HeadingContainer = tw.div``;
const Heading = tw(SectionHeading)``;
const Subheading = tw(SubheadingBase)`text-center mb-3`;
const Description = tw(SectionDescription)`mx-auto text-center`;

const Cards = tw.div`flex flex-wrap flex-row justify-center sm:max-w-2xl lg:max-w-5xl mx-auto mb-16`;
const Card = tw.div`mt-24 w-full sm:w-1/2 lg:w-1/3 flex flex-col items-center`;
const CardImage = styled.div`
  ${(props) => css`background-image: url("${props.imageSrc}");`}
  ${tw`w-64 h-64 bg-contain bg-center rounded`}
`;
const CardContent = styled.div`
  ${tw`flex flex-col items-center mt-6`}
  .position {
    ${tw`uppercase font-bold tracking-widest text-xs text-primary-500`}
  }
  .name {
    ${tw`mt-1 text-xl font-medium text-gray-900`}
  9
`;

const CardLinks = styled.div`
  ${tw`mt-6 flex`}
  .link {
    ${tw`mr-8 last:mr-0 text-gray-400 hocus:text-primary-500 transition duration-300`}
    .icon {
      ${tw`fill-current w-6 h-6`}
    }
  }
`;

export default () => {
    const { t } = useTranslation();

    const cards = [
        {
            position: t('team.positions.founder'),
            name: 'Julien Griffoul',
            // links: [
            //     {
            //         url: 'https://github.com/juliengriffoul',
            //         icon: () => (
            //             <img src={githubIcon} alt="" className="icon" style={{ filter: 'invert(.7)' }} />
            //         ),
            //     },
            //     {
            //         url: 'https://www.linkedin.com/in/julien-griffoul/',
            //         icon: () => (
            //             <img src={linkedinIcon} alt="" className="icon" style={{ filter: 'invert(.7)' }} />
            //         ),
            //     },
            // ],
        },
        {
            position: t('team.positions.devops'),
            name: 'Patrick-Ivann Origo',
            // links: [
            //     {
            //         url: 'https://www.linkedin.com/in/patrick-ivann-origo-064a2b15b/',
            //         icon: () => (
            //             <img src={linkedinIcon} alt="" className="icon" style={{ filter: 'invert(.7)' }} />
            //         ),
            //     },
            // ],
        },
    ];

    return (
        <Container>
            <ContentWithPaddingXl>
                <HeadingContainer>
                    <Subheading>{t('team.subheading')}</Subheading>
                    <Heading>{t('team.heading')}</Heading>
                    <Description>{t('team.description')}</Description>
                </HeadingContainer>
                <Cards>
                    {cards.map((card, index) => (
                        <Card key={index}>
                            <CardImage imageSrc={image} />
                            <CardContent>
                                <span className="position">{card.position}</span>
                                <span className="name">{card.name}</span>
                                {card.links && (
                                    <CardLinks>
                                        {card.links.map((link, linkIndex) => (
                                            <a key={linkIndex} target="_blank" className="link" href={link.url}>
                                                {link.icon()}
                                            </a>
                                        ))}
                                    </CardLinks>
                                )}
                            </CardContent>
                        </Card>
                    ))}
                </Cards>
            </ContentWithPaddingXl>
        </Container>
    );
};
